import type { TrialInAlgolia } from '@modules/trials/types/TrialInAlgolia'
import { FALLBACK_CONDITION_NAME } from './getPrimaryConditionFromTrial'
import { FALLBACK_TREATMENT_NAME } from './getPrimaryTreatmentNameFromTrial'

export default function checkTrialValidity(
  trial: Pick<
    TrialInAlgolia,
    'primaryCondition' | 'primaryTreatment' | 'trialLocations'
  >,
): boolean {
  return Boolean(
    conditionIsValid(trial.primaryCondition) &&
      trial.trialLocations &&
      trial.trialLocations.length > 0,
  )
}

export function checkTreatmentAndConditionValidity(
  trial: Pick<TrialInAlgolia, 'primaryCondition' | 'primaryTreatment'>,
): boolean {
  return Boolean(
    conditionIsValid(trial.primaryCondition) &&
      treatmentIsValid(trial.primaryTreatment),
  )
}

function conditionIsValid(condition?: string) {
  return (
    condition && condition.length > 0 && condition !== FALLBACK_CONDITION_NAME
  )
}

function treatmentIsValid(treatment?: string) {
  return (
    treatment && treatment.length > 0 && treatment !== FALLBACK_TREATMENT_NAME
  )
}
