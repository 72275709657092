import type {
  Coordinates,
  StringCoordinates,
} from '@components/app/TrialSearch/LocationConfiguration'
import type { Geolocation } from '@lib/types/Geolocation'

export const geoToCoordinates = ({
  latitude,
  longitude,
}: Geolocation): Coordinates => {
  return {
    lat: latitude ?? undefined,
    lng: longitude ?? undefined,
  }
}

export const geoToString = (geolocation: Geolocation) => {
  return {
    lat: geolocation.latitude ? `${geolocation.latitude}` : '',
    lng: geolocation.longitude ? `${geolocation.longitude}` : '',
  }
}

export const stringCoordsToNumber = ({
  lat,
  lng,
}: StringCoordinates): Coordinates => {
  return {
    lat: lat ? parseFloat(lat) : undefined,
    lng: lng ? parseFloat(lng) : undefined,
  }
}

export const stringCoordsToGeo = ({
  lat,
  lng,
}: StringCoordinates): Geolocation => {
  return {
    latitude: lat ? parseFloat(lat) : null,
    longitude: lng ? parseFloat(lng) : null,
  }
}
