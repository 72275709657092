import { IconPrefix } from '@components/primitives/Icon/IconPrefix'
import { Text } from '@components/primitives/Text'
import { faGlasses } from '@fortawesome/pro-regular-svg-icons/faGlasses'
import { faHouseChimneyMedical } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyMedical'
import { faMoneyCheckDollarPen } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckDollarPen'
import type { TrialLocationAssociation } from '@modules/centralSites/helpers/getCentralSiteDataBySlug'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import sortBy from 'lodash/sortBy'

interface RelevantContactsProps {
  trial: TrialForSummaryCard
}
type TrialLocationFacility = Pick<TrialLocationAssociation, 'facility'>

export function RelevantContacts({ trial }: RelevantContactsProps) {
  const locationLabel = (locations: TrialLocationFacility[]) => {
    const locationCount = locations.length
    if (locationCount === 0) {
      return null
    }

    const locationToDisplay = sortBy(locations, 'id')[0]!.facility
    if (locationCount === 1) {
      return locationToDisplay
    } else {
      return `${locationToDisplay} (+${locationCount - 1} Sites)`
    }
  }

  const piNameLabel = (trial: TrialForSummaryCard) => {
    if (trial.piNames?.length > 0) {
      return trial.piNames[0]
    } else if (trial.overallOfficialNames?.length > 0) {
      return trial.overallOfficialNames[0]
    } else {
      return null
    }
  }

  return (
    <div className='mt-4 flex flex-col justify-between text-neutral500 lg:flex-row'>
      <span className='mr-4'>
        {trial.trialLocations?.length > 0 && (
          <IconPrefix icon={faHouseChimneyMedical}>
            <Text
              className='line-clamp-1 inline text-neutral500'
              styleName='p-tiny'
              value={locationLabel(trial.trialLocations)}
            />
          </IconPrefix>
        )}
      </span>
      <span className='mr-4'>
        {piNameLabel(trial) && (
          <IconPrefix icon={faGlasses}>
            <Text
              className='line-clamp-1 inline text-neutral500'
              styleName='p-tiny'
              value={piNameLabel(trial)}
            />
          </IconPrefix>
        )}
      </span>
      <span>
        {trial.sponsorNames?.length > 0 && (
          <IconPrefix icon={faMoneyCheckDollarPen}>
            <Text
              className='line-clamp-1 inline text-neutral500'
              styleName='p-tiny'
              value={trial.sponsorNames[0]}
            />
          </IconPrefix>
        )}
      </span>
    </div>
  )
}
