import { PartnerDomainsToSlugs } from '@modules/partnerTrialFinder/consts'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

/**
 * Detects if we are viewing the partner page at `/partner/` or on a partner subdomain
 */
export default function useIsPartnerPage(
  {
    includeEmbedPage = true,
    onlyEmbedPage = false,
  }: {
    includeEmbedPage?: boolean
    onlyEmbedPage?: boolean
  } = { includeEmbedPage: true, onlyEmbedPage: false },
) {
  const hostname = window.location.hostname
  const pathname = usePathname()

  const viewingPartnerPage = () => {
    const viewingPartnerEmbedPage = pathname
      ? pathname.startsWith('/partners') && pathname.includes('/embed')
      : false
    if (viewingPartnerEmbedPage && !includeEmbedPage) {
      return false
    }
    if (viewingPartnerEmbedPage && onlyEmbedPage) {
      return true
    }
    const viewingPartnerPage = pathname
      ? pathname.startsWith('/partners')
      : false
    const viewingPartnerSubdomain = hostname in PartnerDomainsToSlugs
    return viewingPartnerPage || viewingPartnerSubdomain
  }

  const [isPartnerPage, setIsPartnerPage] =
    useState<boolean>(viewingPartnerPage())

  useEffect(() => {
    setIsPartnerPage(viewingPartnerPage())
  }, [hostname, pathname])

  return isPartnerPage
}
