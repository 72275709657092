export const GENERIC_HOSPITAL_PHOTO_IDS = [
  '1b5ce508-c5ea-41ac-cdd7-98550ebef700',
  '49f9f145-d77f-4d48-8d1c-e28d5d038800',
  '380b368c-c6d9-4d1e-c772-91fa3706ac00',
  '1b84e2f7-3d78-46a6-6afc-9bb0d12c2100',
  '4e096053-897b-4366-a615-e16b07413600',
  '680b6f4b-90d7-440c-4154-b3f53d9fcf00',
  'c0b02a74-0546-468b-55bc-4e4c4df24a00',
  '0cfbc96f-a7da-406b-4088-1f7fe2bc4f00',
  'fc213dfe-0b64-411e-5ec2-f8d309d81800',
]
