import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

import { Icon } from '../Icon'

interface MediaCardProps {
  children: React.ReactNode
  className?: string
  desc?: string
  icon?: IconDefinition
  iconClassName?: string
  onClick?: () => void
  subtitle: string
}

export const MediaCard = ({
  children,
  className,
  desc,
  icon,
  iconClassName,
  onClick,
  subtitle,
}: MediaCardProps) => {
  const cardStyles = classMerge(
    'shadow-md',
    'rounded-lg hover:shadow-lg',
    className,
  )

  return (
    <div className={cardStyles} onClick={onClick}>
      {children}
      <div className='min-h-[138px] rounded-b-lg border-t border-neutral200 bg-white p-2 lg:p-4'>
        <Text
          className='line-clamp-1 w-full cursor-pointer no-underline'
          element='h4'
          styleName='p-strong'
          value={subtitle}
        />
        {desc && (
          <span className='flex items-baseline'>
            {/* 16px right margin from the icon to the text */}
            {icon && (
              <Icon className={classMerge('mr-4', iconClassName)} icon={icon} />
            )}
            <Text
              className='line-clamp-1 w-full cursor-pointer text-neutral500 no-underline'
              styleName='p'
              value={desc}
            />
          </span>
        )}
      </div>
    </div>
  )
}
