import type { Gradients } from '@lib/colors/colors'
import { darkerGradients } from '@lib/colors/colors'

/**
 * Get a background gradient Tailwind CSS name. Provide a number to mod off for deterministic results.
 *
 * @param index Number for deterministic results, otherwise random
 */
export const getBackgroundGradient = (
  index: number = Math.floor(Math.random() * darkerGradients.length),
): Gradients => {
  // We want to only show darker gradients to allow for sufficient contrast for white text
  return darkerGradients[index % darkerGradients.length]!
}
