import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { faAward } from '@fortawesome/pro-regular-svg-icons/faAward'
import pluralize from '@lib/utilities/text/pluralize'
import type { TrialInAlgolia } from '@modules/trials/types/TrialInAlgolia'

export const AwardBadgesHeader = ({
  awardBadges,
}: {
  awardBadges: TrialInAlgolia['awardBadges']
}) => {
  if (awardBadges.length < 1) {
    return <></>
  }

  return (
    <Text
      className='text-neutral600'
      styleName='p-small'
      value={
        <>
          <Icon className='mr-2 text-hope600' icon={faAward} />
          {pluralize('Award', awardBadges.length, { includeCount: true })}
        </>
      }
    />
  )
}
