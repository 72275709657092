import type { TrialForSummaryCard } from '../types/TrialForSummaryCard'

type TagProperty = {
  label: string
  matcher: (trial: TrialForSummaryCard) => boolean
  priority: number
}

type TagProperties = TagProperty[]

const tagAttributeProperties: TagProperties = [
  {
    label: 'FDA Approved Drug',
    matcher: (trial) => Boolean(trial.hasFdaApprovedDrug),
    priority: 1,
  },
  {
    label: 'Has No Placebo',
    matcher: (trial) => Boolean(trial.hasNoPlacebo),
    priority: 2,
  },
  {
    label: 'Paid Trial',
    matcher: (trial) => Boolean(trial.isPaidTrial),
    priority: 3,
  },
  {
    label: 'Background Therapy Allowed',
    matcher: (trial) => Boolean(trial.hasBackgroundTherapyAllowed),
    priority: 4,
  },
  {
    label: 'Immunotherapy',
    matcher: (trial) => Boolean(trial.interventionHasImmunotherapy),
    priority: 5,
  },
  {
    label: 'New This Month',
    matcher: (trial) => Boolean(trial.newThisMonth),
    priority: 6,
  },
  {
    label: 'Online Trial',
    matcher: (trial) => Boolean(trial.isOnline),
    priority: 11,
  },
  {
    label: 'Travel Is Reimbursed',
    matcher: (trial) => Boolean(trial.hasTravelReimbursement),
    priority: 12,
  },
  {
    label: 'Accommodation Is Reimbursed',
    matcher: (trial) => Boolean(trial.hasAccommodationReimbursement),
    priority: 13,
  },
  {
    label: 'Other Reimbursement Provided',
    matcher: (trial) => Boolean(trial.hasOtherReimbursement),
    priority: 14,
  },
  {
    label: 'No Cost For Treatment',
    matcher: (trial) => Boolean(trial.hasNoCostForTreatment),
    priority: 15,
  },
  {
    label: 'No Prior Treatment',
    matcher: (trial) =>
      Boolean(trial.stageOfTreatment.includes('Newly Diagnosed')),
    priority: 19,
  },
  {
    label: '1 Prior Treatment',
    matcher: (trial) =>
      Boolean(trial.stageOfTreatment.includes('1 Prior Treatment')),
    priority: 20,
  },
  {
    label: '2 Prior Treatments',
    matcher: (trial) =>
      Boolean(trial.stageOfTreatment.includes('2 Prior Treatments')),
    priority: 21,
  },
  {
    label: '3+ Prior Treatment',
    matcher: (trial) =>
      Boolean(trial.stageOfTreatment.includes('3 Prior Treatments')),
    priority: 22,
  },
  {
    label: 'Drug',
    matcher: (trial) => Boolean(trial.interventionTypes.includes('Drug')),
    priority: 24, // Use this array order for this priority level
  },
  {
    label: 'Injection',
    matcher: (trial) => Boolean(trial.interventionTypes.includes('Injection')),
    priority: 24,
  },
  {
    label: 'Device',
    matcher: (trial) => Boolean(trial.interventionTypes.includes('Device')),
    priority: 24,
  },
  {
    label: 'Dietary Supplement',
    matcher: (trial) =>
      Boolean(trial.interventionTypes.includes('Dietary Supplement')),
    priority: 24,
  },
  {
    label: 'Behavior',
    matcher: (trial) => Boolean(trial.interventionTypes.includes('Behavior')),
    priority: 24,
  },
  {
    label: 'Stage I',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Stage I')),
    priority: 26,
  },
  {
    label: 'Stage II',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Stage II')),
    priority: 27,
  },
  {
    label: 'Stage III',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Stage III')),
    priority: 28,
  },
  {
    label: 'Stage IV',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Stage IV')),
    priority: 29,
  },
  {
    label: 'Grade I',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Grade I')),
    priority: 30,
  },
  {
    label: 'Grade II',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Grade II')),
    priority: 31,
  },
  {
    label: 'Grade III',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Grade III')),
    priority: 32,
  },
  {
    label: 'Grade IV',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Grade IV')),
    priority: 33,
  },
  {
    label: 'Class I',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Class I')),
    priority: 34,
  },
  {
    label: 'Class II',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Class II')),
    priority: 35,
  },
  {
    label: 'Class III',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Class III')),
    priority: 36,
  },
  {
    label: 'Class IV',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Class IV')),
    priority: 37,
  },
  {
    label: 'Class V',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Class V')),
    priority: 38,
  },
  {
    label: 'Class VI',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Class VI')),
    priority: 39,
  },
  {
    label: 'Advanced',
    matcher: (trial) =>
      Boolean(trial.stageOfDisease.includes('Locally Advanced')),
    priority: 40,
  },
  {
    label: 'Metastatic',
    matcher: (trial) => Boolean(trial.stageOfDisease.includes('Metastatic')),
    priority: 41,
  },
  {
    label: 'Senior-friendly',
    matcher: (trial) => Boolean(trial.ageList.includes('Older Adult')),
    priority: 42,
  },
  {
    label: 'Child-friendly',
    matcher: (trial) => Boolean(trial.ageList.includes('Child')),
    priority: 43,
  },
]

export default function getSortedTagListFromTrial(trial: TrialForSummaryCard) {
  return tagAttributeProperties
    .filter((tagProperty) => tagProperty.matcher(trial))
    .sort((a, b) => a.priority - b.priority) // lowest priority first
    .map((tagProperty) => tagProperty.label)
}
