import { Badge } from '@components/primitives/Badge'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck'

export const VerifiedBadge = () => (
  <Badge
    className='absolute m-2 flex gap-2 rounded-full'
    colorGroup='primary'
    icon={faBadgeCheck}
    label='Verified'
    variant='accent'
  />
)
