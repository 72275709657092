import { type Hit } from '@algolia/client-search'
import { getTrialBriefSummaryAttribute } from '@modules/trials/helpers/getTrialBriefSummary'
import { type TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import { Highlight } from 'react-instantsearch'

export const HighlightedSummaryText = ({
  trial,
}: {
  trial: TrialForSummaryCard
}) => {
  const trialHit = trial as Hit<any> // This trial prop is actually an Algolia hit, but it would be a lot of work to refactor the code to prop drill the type down
  return (
    <Highlight
      attribute={getTrialBriefSummaryAttribute(trial) as any}
      hit={trialHit}
    />
  )
}
