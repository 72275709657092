'use client'

import {
  trackEventWithFacebook,
  TrackingEvent,
  type TrackingSource,
} from '@lib/tracking'
import type { UserSearchParams } from '@pages/api/v1/search'

export const trackViewDetailsClick = (
  linkToTrial: string,
  trackingSource: TrackingSource,
  userSearchParams?: UserSearchParams,
) => {
  trackEventWithFacebook(TrackingEvent.TRIAL_LISTING_CLICKED, {
    path: linkToTrial,
    source: trackingSource,
    val: JSON.stringify(userSearchParams),
  })
}
