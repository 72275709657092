import { Text } from '@components/primitives/Text'
import { getPreferredTrialTitleForAlgoliaTrial } from '@modules/trials/helpers/getPreferredTrialTitle'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import { createElement } from 'react'
import ConditionName from '../ConditionName'

export default function TreatmentForCondition({
  applyCondition,
  trial,
}: {
  applyCondition?: string
  trial: TrialForSummaryCard
}) {
  const conditionName =
    ConditionName(applyCondition ?? trial.primaryCondition) ?? ''

  const preferredTitle = trial.abbreviatedTitle ?? trial.conjuredTitle
  const backupTitle = getPreferredTrialTitleForAlgoliaTrial(
    trial,
    conditionName,
  )
  const [treatment, condition] = backupTitle.split(' for ')
  const hasTreatmentAndCondition = Boolean(treatment) && Boolean(condition)

  let children
  if (preferredTitle) {
    children = (
      <Text
        className='line-clamp-2 text-black'
        hideTitle={true}
        styleName='h3'
        value={preferredTitle}
      />
    )
  } else if (hasTreatmentAndCondition) {
    children = (
      <>
        <Text
          className='line-clamp-2 text-black'
          hideTitle={true}
          styleName='h3'
          value={treatment}
        />
        <Text
          className='line-clamp-2 text-black'
          hideTitle={true}
          styleName='p-small'
          value={` for ${condition}`}
        />
      </>
    )
  } else {
    children = (
      <Text
        className='line-clamp-2 text-black'
        hideTitle={true}
        styleName='h3'
        value={backupTitle}
      />
    )
  }
  return createElement('h3', { title: preferredTitle ?? backupTitle }, children)
}
