import { RelevantContacts } from '@components/app/TrialSummaryCard/RelevantContacts'
import { GradientFill } from '@components/primitives/Gradient/gradientFill'
import { CloudflareImage } from '@components/primitives/Image/Cloudflare'
import { MediaCard } from '@components/primitives/MediaCard'
import { Text } from '@components/primitives/Text'
import DynamicRoutes from '@lib/routes/DynamicRoutes'
import { TrackingSource } from '@lib/tracking'
import { getBackgroundGradient } from '@lib/utilities/getBackgroundGradient'
import { anonymousListingPagePhoto } from '@modules/images/pages/trials'
import checkTrialValidity, {
  checkTreatmentAndConditionValidity,
} from '@modules/trials/helpers/checkTrialValidity'
import type { TrialForGradientCard } from '@modules/trials/helpers/getRelatedListings'
import { getTrialBriefSummary } from '@modules/trials/helpers/getTrialBriefSummary'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import type { TrialInAlgolia } from '@modules/trials/types/TrialInAlgolia'
import type { UserSearchParams } from '@pages/api/v1/search'
import { KeyTrialInformation } from './KeyTrialInformation'
import {
  LearnMoreButton,
  NavigateToTrialWrapper,
} from './NavigateToTrialWrapper'
import { SearchPageTrialSummaryCard } from './SearchPageTrialSummaryCard'
import StatusPills from './StatusPills'
import TreatmentForCondition from './TreatmentForCondition'
import TrialSiteImage from './TrialSiteImage'
import { VerifiedBadge } from './VerifiedBadge'
import { getDigitFromNctId } from './getDigitFromNctId'

export type TrialSummaryCardProps = {
  applyCondition?: string
  className?: string
  fireSearchEvent?: boolean
  index?: number
  isAboveFold?: boolean
  isFiltered?: boolean
  shortcode?: string | null
  trackingSource: TrackingSource
  trial: TrialForSummaryCard
  userSearchParams?: UserSearchParams
}

export const getLinkToTrial = ({
  shortcode,
  trial,
}: {
  shortcode?: string | null
  trial: Pick<TrialInAlgolia, 'slug'>
}) => {
  return shortcode
    ? DynamicRoutes.go(shortcode, DynamicRoutes.trialNaked(trial.slug ?? ''))
    : DynamicRoutes.trialNaked(trial.slug ?? '')
}

/**
 * Card component for displaying a trial in a list of trials.
 */
export function TrialSummaryCard({
  applyCondition,
  fireSearchEvent = false,
  index,
  isAboveFold = false,
  isFiltered = false,
  shortcode,
  trackingSource,
  trial,
  userSearchParams,
}: TrialSummaryCardProps) {
  // Skip render in the case that we don't know the primary treatment, condition, or location(s)
  if (!checkTrialValidity(trial)) {
    return null
  }

  const linkToTrial = getLinkToTrial({ shortcode, trial })

  const props = {
    applyCondition,
    fireSearchEvent,
    index,
    isAboveFold,
    isFiltered,
    shortcode,
    trackingSource,
    trial,
    userSearchParams,
  }

  if (isFiltered) {
    return <SearchPageTrialSummaryCard {...props} />
  }

  return (
    <NavigateToTrialWrapper
      fireSearchEvent={fireSearchEvent}
      linkToTrial={linkToTrial}
      trackingSource={trackingSource}
      userSearchParams={userSearchParams}
    >
      <DirectoryPageTrialSummaryCard {...props} />
    </NavigateToTrialWrapper>
  )
}

const DirectoryPageTrialSummaryCard = ({
  applyCondition,
  className,
  isAboveFold,
  isFiltered,
  shortcode,
  trackingSource,
  trial,
}: TrialSummaryCardProps) => {
  const linkToTrial = getLinkToTrial({ shortcode, trial })
  const trialDigitOffset = getDigitFromNctId(trial)

  return (
    <div className={className}>
      <div className='flex-col overflow-hidden rounded-2xl border-2 border-neutral100 bg-neutral100 hover:border-neutral500 md:flex md:flex-row'>
        <div
          className={`flex w-full flex-col justify-start rounded-t-2xl bg-neutral100 md:rounded-r-none md:rounded-bl-2xl lg:w-1/3`}
        >
          <div className='relative'>
            {trial.isVerified && <VerifiedBadge />}
          </div>
          <TrialSiteImage
            className='h-[227px] w-full md:h-[168px]'
            containerClass='h-[227px] w-full md:h-[168px]'
            height={227}
            isAboveFold={isAboveFold}
            trialDigitOffset={trialDigitOffset}
            trialLocations={trial.trialLocations}
            width={341}
          />

          <div className='p-4 md:p-6 '>
            <TreatmentForCondition
              applyCondition={applyCondition}
              trial={trial}
            />
            <KeyTrialInformation trial={trial} />
          </div>
        </div>
        <div className='flex w-full flex-col rounded-b-2xl bg-white p-4 md:rounded-bl-none md:rounded-tr-2xl md:p-8 lg:w-2/3'>
          <div className='flex grow flex-col gap-4 md:flex-col-reverse'>
            <div className='mb-4 grow'>
              <Text
                className='line-clamp-5 text-neutral700'
                value={getTrialBriefSummary({ ...trial })}
              />
            </div>
            <div className='flex'>
              <StatusPills trial={trial} />
            </div>
          </div>

          <div className='flex flex-col-reverse lg:flex-col'>
            <div className='mx-auto my-8 mb-4 w-full md:mx-0'>
              <LearnMoreButton
                linkToTrial={linkToTrial}
                trackingSource={trackingSource}
              />
            </div>
            {!isFiltered && <RelevantContacts trial={trial} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const PhotoTrialSummaryCard = function ({
  className,
  shortcode,
  trackingSource = TrackingSource.RELATED_LINKS_TRIAL,
  trial,
}: {
  className?: string
  shortcode?: string | null
  trackingSource: TrackingSource
  trial: TrialForGradientCard
}) {
  // Skip render in the case that we don't know the primary treatment, condition
  if (!checkTreatmentAndConditionValidity(trial)) {
    return null
  }
  const linkToTrial = getLinkToTrial({ shortcode, trial })

  return (
    <NavigateToTrialWrapper
      className={className}
      fireSearchEvent={false}
      linkToTrial={linkToTrial}
      trackingSource={trackingSource}
    >
      <MediaCard
        className='border border-neutral200'
        desc={`for ${trial.primaryCondition}`}
        subtitle={trial.primaryTreatment}
      >
        <div className='bg-organicDawn rounded-t-[7px] p-4 pb-0'>
          <CloudflareImage
            alt='Patient Care'
            className='w-full rounded-t-lg'
            height={200}
            imageId={anonymousListingPagePhoto}
            width={300}
          />
        </div>
      </MediaCard>
    </NavigateToTrialWrapper>
  )
}

const GradientTrialSummaryCard = function ({
  className,
  shortcode,
  trackingSource = TrackingSource.RELATED_LINKS_TRIAL,
  trial,
}: {
  className?: string
  shortcode?: string | null
  trackingSource: TrackingSource
  trial: TrialForGradientCard
}) {
  // Skip render in the case that we don't know the primary treatment, condition
  if (!checkTreatmentAndConditionValidity(trial)) {
    return null
  }
  const linkToTrial = getLinkToTrial({ shortcode, trial })

  return (
    <NavigateToTrialWrapper
      className={className}
      fireSearchEvent={false}
      linkToTrial={linkToTrial}
      trackingSource={trackingSource}
    >
      <MediaCard
        desc={`for ${trial.primaryCondition}`}
        subtitle={trial.primaryTreatment}
      >
        <GradientFill
          backgroundGradient={getBackgroundGradient(
            trial.slug?.charCodeAt(trial.slug?.length - 1),
          )}
          className='h-[200px] w-[300px]'
          grainy
        />
      </MediaCard>
    </NavigateToTrialWrapper>
  )
}

TrialSummaryCard.Photo = PhotoTrialSummaryCard
TrialSummaryCard.Gradient = GradientTrialSummaryCard
