import type { ClinicalTrial } from '@prisma/client'
import type { TrialInAlgolia } from '../types/TrialInAlgolia'

export type TrialWithDavinciBriefSummaryBlob = Pick<
  ClinicalTrial,
  'davinciBriefSummary'
>

type TrialWithSummaries = Pick<
  TrialInAlgolia,
  'briefSummary' | 'latestDavinciBriefSummary' | 'nlpBriefSummary'
>

/**
 * Return a string representation of the brief summary, with highest priority given to the
 * the new nlpBriefSummary, then Davinci brief summary, and finally
 * the one provided by CT gov.
 *
 * @param trial Trial JSON with at least the `briefSummary`, `nlpBriefSummary`, and `latestDavinciBriefSummary` blob
 */
export function getTrialBriefSummary({
  briefSummary,
  latestDavinciBriefSummary,
  nlpBriefSummary,
}: TrialWithSummaries): string {
  if (nlpBriefSummary !== null) {
    return nlpBriefSummary
  } else if (
    hasValidLatestDavinciBriefSummary({
      briefSummary,
      latestDavinciBriefSummary,
      nlpBriefSummary,
    })
  ) {
    return latestDavinciBriefSummary!.stageOne
  } else {
    return briefSummary
  }
}

/**
 * Return a string representation of which attribute to pick for highlighting content in any
 * of the brief summaries, with highest priority given to the new nlpBriefSummary then, the Davinci brief summary,
 * and finally the one provided by CT gov.
 *
 * @param trial Trial JSON with at least the `briefSummary`, `nlpBriefSummary`, and `latestDavinciBriefSummary` blob
 */
export function getTrialBriefSummaryAttribute(trial: TrialWithSummaries) {
  if (trial.nlpBriefSummary !== null) {
    return 'nlpBriefSummary'
  } else if (hasValidLatestDavinciBriefSummary(trial)) {
    return 'latestDavinciBriefSummary.stageOne'
  } else {
    return 'briefSummary'
  }
}

function hasValidLatestDavinciBriefSummary(trial: TrialWithSummaries) {
  return (
    trial.latestDavinciBriefSummary !== undefined &&
    Object.keys(trial.latestDavinciBriefSummary).length > 0 &&
    Object.keys(trial.latestDavinciBriefSummary).includes('stageOne') &&
    Object.keys(trial.latestDavinciBriefSummary).includes('stageTwo')
  )
}
