import { RequestMethod } from '@lib/api/consts'
import ApiRoutes from '@lib/routes/ApiRoutes'
import type {
  UserSearchParams,
  UserSearchResponseData,
} from '@pages/api/v1/search'
import internalJsonFetch from '../internalJsonFetch'

export default async function search(params: UserSearchParams) {
  return await internalJsonFetch<UserSearchResponseData>({
    authenticated: true,
    body: params,
    requestMethod: RequestMethod.POST,
    url: ApiRoutes.v1.search,
  })
}
