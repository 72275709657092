import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import isNaN from 'lodash/isNaN'

export const getDigitFromNctId = (
  trial: Pick<TrialForSummaryCard, 'briefTitle' | 'nctId'>,
) => {
  const maybeNumber = parseInt(trial.nctId.replace('NCT', ''), 10)
  if (isNaN(maybeNumber)) {
    return trial.briefTitle.length
  }

  return maybeNumber
}
