import search from '@lib/api/search'
import LocalStorageItem from '@lib/browsers/localStorage/LocalStorageItem'
import type { UserSearchParams } from '@pages/api/v1/search'

const userSearchStorage = new LocalStorageItem('userSearchToken')

export default async function saveUserSearch(params: UserSearchParams | null) {
  try {
    if (!params) {
      return null
    }

    const result = await search({
      ...params,
      token: (userSearchStorage.getItem() as string) ?? undefined,
    })

    if (!result.success) {
      return null
    }

    if (result.data?.params.token) {
      userSearchStorage.setItem(result.data.params.token)
    }
  } catch (_error) {}
}
