'use client'

import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { AnimatedAccordion } from '@components/primitives/AnimatedAccordion'
import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { faBarsProgress } from '@fortawesome/pro-regular-svg-icons/faBarsProgress'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faGlasses } from '@fortawesome/pro-regular-svg-icons/faGlasses'
import { faHouseMedical } from '@fortawesome/pro-regular-svg-icons/faHouseMedical'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot'
import { faMicroscope } from '@fortawesome/pro-regular-svg-icons/faMicroscope'
import { faMoneyCheckDollarPen } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckDollarPen'
import { faTrophy } from '@fortawesome/pro-regular-svg-icons/faTrophy'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import type { Url } from '@lib/routes'
import urlWithQueryParams from '@lib/routes/helpers/urlWithQueryParams'
import {
  trackEventWithFacebook,
  TrackingEvent,
  TrackingSource,
} from '@lib/tracking'
import pluralize from '@lib/utilities/text/pluralize'
import uniq from '@lib/utilities/uniq'
import { getPhaseFromPhaseList } from '@modules/trials/helpers/getPhaseFromPhaseList'
import { getPreferredTrialTitleForAlgoliaTrial } from '@modules/trials/helpers/getPreferredTrialTitle'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { getLinkToTrial, type TrialSummaryCardProps } from '.'
import { ApplyToTrialButton } from '../Button/ApplyToTrial'
import { AwardBadgePill } from '../TrialAwardBadges/AwardBadgePill'
import { AwardBadgesHeader } from '../TrialAwardBadges/AwardBadgesHeader'
import { ClosestFacility, useTrialAttributes } from './AttributesString'
import { EligibilityMatch } from './EligibilityMatch'
import { getDigitFromNctId } from './getDigitFromNctId'
import { HighlightedSummaryText } from './HighlightedSummaryText'
import TrialSiteImage from './TrialSiteImage'

export const SearchPageTrialSummaryCard = ({
  index,
  shortcode,
  trial,
}: TrialSummaryCardProps) => {
  const { Distance, NearestCity } = useTrialAttributes(trial.trialLocations)
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const [isExpanded, setIsExpanded] = useState(false)
  const linkToTrial = getLinkToTrial({ shortcode, trial })
  const [searchCondition, setSearchCondition] = useState(
    searchParamFromUrl('condition'),
  )

  useEffect(() => {
    setSearchCondition(searchParamFromUrl('condition'))
  }, [pathName, searchParams])

  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const conditionToDisplay =
    searchCondition || trial.primaryCondition || trial.conditions[0] || ''
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  const phaseLabel = getPhaseFromPhaseList(trial.phaseList)

  const trialDigitOffset = getDigitFromNctId(trial)

  const awardBadges = trial.awardBadges ?? []

  const currentParams = Object.fromEntries(searchParams?.entries() ?? [])
  const linkWithCondition = urlWithQueryParams(linkToTrial as string, {
    condition: searchCondition ?? '',
    ...currentParams,
  })
  const trialTitle = getPreferredTrialTitleForAlgoliaTrial(
    trial,
    conditionToDisplay,
  )

  return (
    <>
      <Link
        href={linkWithCondition}
        newTab={true}
        onClick={() =>
          trackEventWithFacebook(TrackingEvent.TRIAL_LISTING_CLICKED, {
            index,
            source: TrackingSource.SEARCH,
          })
        }
      >
        <div className='block md:hidden'>
          <div className='flex cursor-pointer flex-col gap-2 rounded-[16px] border border-neutral200 bg-white p-4 shadow'>
            <div className='flex gap-4'>
              <TrialSiteImage
                className='rounded'
                containerClass='h-[86px] w-[86px]'
                imageIndex={index}
                trialDigitOffset={trialDigitOffset}
                trialLocations={trial.trialLocations}
              />

              <div className='w-2/3'>
                <TreatmentForConditionTitle trialTitle={trialTitle} />
              </div>
            </div>

            {awardBadges.length > 0 && (
              <div>
                <Icon className='mr-2 inline text-hope600' icon={faTrophy} />
                <Text
                  className='inline text-neutral900'
                  styleName='p-small'
                  value={pluralize('award', awardBadges.length, {
                    includeCount: true,
                  })}
                />
              </div>
            )}

            <div>
              <Icon className='mr-2 inline text-blue600' icon={faLocationDot} />
              <Text
                className='inline text-neutral600'
                styleName='p-small'
                value={`${NearestCity} ${Distance ?? ''}`}
              />
            </div>

            <div>
              <Icon
                className='mr-2 inline text-green600'
                icon={faBarsProgress}
              />
              <Text
                className='inline text-neutral600'
                styleName='p-small'
                value={`${phaseLabel !== 'N/A' ? phaseLabel : 'Unphased'}, `}
              />
              <Text
                className='inline text-neutral600'
                styleName='p-small'
                value={
                  trial.overallStatus === 'Recruiting'
                    ? 'Recruiting'
                    : 'Waitlist Available'
                }
              />
              {trial.isVerified && (
                <Icon className='ml-1 text-primary600' icon={faBadgeCheck} />
              )}
            </div>

            <Button.Link
              className='w-full rounded-full'
              color='black'
              href={linkWithCondition}
              newTab
              onClick={() =>
                trackEventWithFacebook(TrackingEvent.TRIAL_LISTING_CLICKED, {
                  index,
                  source: TrackingSource.SEARCH,
                })
              }
              value='Learn More'
            />
            <ApplyToTrialButton
              buttonText='Contact Trial'
              className='border border-neutral300'
              color='white'
              newTab
              prefetch={false}
              trackingCallback={() =>
                trackEventWithFacebook(TrackingEvent.APPLY_NOW_CLICKED, {
                  index,
                  source: TrackingSource.SEARCH,
                })
              }
              trial={trial}
            />
          </div>
        </div>
      </Link>

      <div className='hidden md:block'>
        <div className='rounded-[16px] bg-neutral50 shadow'>
          <TopHalfOfSummaryCard
            index={index}
            isExpanded={isExpanded}
            linkWithCondition={linkWithCondition}
            setIsExpanded={setIsExpanded}
            shortcode={shortcode}
            trial={trial}
            trialDigitOffset={trialDigitOffset}
            trialTitle={trialTitle}
          />
          {isExpanded ? (
            <Link href={linkWithCondition} newTab>
              <BottomHalfOfSummaryCard
                index={index}
                isExpanded={isExpanded}
                linkWithCondition={linkWithCondition}
                trial={trial}
              />
            </Link>
          ) : (
            <BottomHalfOfSummaryCard
              index={index}
              isExpanded={isExpanded}
              linkWithCondition={linkWithCondition}
              trial={trial}
            />
          )}
        </div>
      </div>
    </>
  )
}

const BottomHalfOfSummaryCard = ({
  index,
  isExpanded,
  linkWithCondition,
  trial,
}: {
  index?: number
  isExpanded: boolean
  linkWithCondition: Url
  trial: TrialForSummaryCard
}) => {
  const piNames = uniq([...trial.piNames, ...trial.overallOfficialNames]).join(
    ', ',
  )

  const awardBadges = trial.awardBadges ?? []
  const primaryTreatments =
    trial.treatments?.filter((t) => t.isPrimary).slice(0, 4) ?? []

  return (
    <AnimatedAccordion isExpanded={isExpanded}>
      <div className='flex w-full gap-4 rounded-b-[16px] p-6'>
        <div className='flex w-1/2 flex-col gap-4'>
          <div>
            <Text
              className='font-semibold'
              styleName='p-small'
              value='Summary'
            />
            <Text
              className='line-clamp-4 text-neutral600'
              styleName='p-small'
              value={<HighlightedSummaryText trial={trial} />}
            />
          </div>

          {awardBadges.length > 0 && (
            <div>
              <Text
                className='font-semibold'
                styleName='p-small'
                value='Efficacy & Safety Awards'
              />
              <div className='flex flex-wrap gap-2'>
                {awardBadges.map((award) => (
                  <AwardBadgePill
                    key={award.id}
                    label={award.label}
                    name={award.name}
                    tags={award.tags}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className='w-1/2'>
          <div className='rounded-[8px] border border-neutral200 bg-white p-4'>
            <div className='flex flex-col gap-3'>
              <div className='flex items-center gap-2 border-b-2 border-neutral200 pb-3'>
                <Icon
                  className='text-primary600'
                  icon={faHouseMedical}
                  size='lg'
                />
                <div>
                  <ClosestFacility
                    trialLocations={trial.trialLocations ?? []}
                  />
                  {/* TODO - travel reimbursement? */}
                </div>

                {trial.isVerified && (
                  <Icon className='text-primary600' icon={faBadgeCheck} />
                )}
              </div>

              {primaryTreatments.length > 0 && (
                <div>
                  <Text
                    className='text-neutral600'
                    styleName='p-small-strong'
                    value='Treatments'
                  />
                  {primaryTreatments.map((treatment) => (
                    <Text
                      className='text-neutral600'
                      key={treatment.name}
                      styleName='p-small'
                      value={
                        <>
                          <Icon className='mr-2' icon={faMicroscope} />
                          {treatment.name} (
                          {treatment.class ?? treatment.domain})
                        </>
                      }
                    />
                  ))}
                </div>
              )}
              {/* TODO - I could add some other info about the sponsors and stuff right?  */}

              <div>
                {(trial.sponsorNames.length > 0 || piNames) && (
                  <Text
                    className='text-neutral600'
                    styleName='p-small-strong'
                    value='Sponsors and Investigators'
                  />
                )}

                {trial.sponsorNames?.length > 0 && (
                  <div>
                    <Text
                      className='line-clamp-1 inline text-neutral500'
                      styleName='p-small'
                      value={
                        <>
                          <Icon className='mr-2' icon={faMoneyCheckDollarPen} />
                          {trial.sponsorNames?.join(', ')}
                        </>
                      }
                    />
                  </div>
                )}
                {piNames && (
                  <div>
                    <Text
                      className='line-clamp-1 inline text-neutral500'
                      styleName='p-small'
                      value={
                        <>
                          <Icon className='mr-2' icon={faGlasses} />
                          {piNames}
                        </>
                      }
                    />
                  </div>
                )}
              </div>
              <Button.Link
                className='mt-3 w-full rounded-full'
                color='black'
                href={linkWithCondition}
                newTab
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  trackEventWithFacebook(TrackingEvent.TRIAL_LISTING_CLICKED, {
                    index,
                    source: TrackingSource.SEARCH,
                  })
                }}
                value='Learn More'
              />
              <ApplyToTrialButton
                buttonText='Contact Trial'
                className='border border-neutral300'
                color='white'
                newTab
                prefetch={false}
                swallowClick={true}
                trackingCallback={() => {
                  trackEventWithFacebook(TrackingEvent.APPLY_NOW_CLICKED, {
                    index,
                    source: TrackingSource.SEARCH,
                  })
                }}
                trial={trial}
              />
            </div>
          </div>
        </div>
      </div>
    </AnimatedAccordion>
  )
}

const TopHalfOfSummaryCard = ({
  index,
  isExpanded,
  linkWithCondition,
  setIsExpanded,
  trial,
  trialDigitOffset,
  trialTitle,
}: {
  index?: number
  isExpanded: boolean
  linkWithCondition: Url
  setIsExpanded: (val: boolean) => void
  shortcode?: string | null
  trial: TrialForSummaryCard
  trialDigitOffset: number
  trialTitle: string
}) => {
  const { Distance, NearestCity } = useTrialAttributes(trial.trialLocations)
  const phaseLabel = getPhaseFromPhaseList(trial.phaseList)
  const awardBadges = trial.awardBadges ?? []
  const isPartnerEmbedPage = useIsPartnerPage({ onlyEmbedPage: true })

  return (
    <div
      className='flex cursor-pointer justify-between gap-2 rounded-[16px] border border-neutral200 bg-white p-8 hover:border-neutral300 hover:shadow-md'
      onClick={() => {
        trackEventWithFacebook(TrackingEvent.DIRECTORY_CARD_CLICKED, {
          index,
        })
        setIsExpanded(!isExpanded)
      }}
    >
      <div className='w-5/12 text-left'>
        <div className='flex items-start gap-4'>
          <TrialSiteImage
            className='rounded'
            containerClass='h-[86px] w-[86px]'
            imageIndex={index}
            trialDigitOffset={trialDigitOffset}
            trialLocations={trial.trialLocations}
          />

          <div className='w-2/3'>
            <Link
              className='hover:underline'
              href={linkWithCondition}
              newTab={true}
              onClick={() =>
                trackEventWithFacebook(TrackingEvent.TRIAL_LISTING_CLICKED, {
                  index,
                  source: TrackingSource.SEARCH,
                })
              }
            >
              <TreatmentForConditionTitle trialTitle={trialTitle} />
            </Link>
          </div>
        </div>
      </div>

      <div className='w-3/12 text-left'>
        <Text
          className='inline font-semibold'
          styleName={isPartnerEmbedPage ? 'p-small' : 'p'}
          value={
            <span className='line-clamp-2'>
              {NearestCity}
              {!!Distance && (
                <Text
                  className='inline text-sm text-neutral600'
                  styleName='p-small'
                  value={` ${Distance}`}
                />
              )}
            </span>
          }
        />

        <div className='flex items-center'>
          {trial.overallStatus === 'Recruiting' ? (
            <>
              <Icon className='mr-2 text-primary400' icon={faUser} />
              <Text
                className='text-neutral600'
                styleName={isPartnerEmbedPage ? 'p-tiny' : 'p-small'}
                value='Recruiting'
              />
            </>
          ) : (
            <>
              <Icon className='mr-2 text-warm500' icon={faUser} />
              <Text
                className='inline text-neutral600'
                styleName='p-small'
                value='Waitlist'
              />
            </>
          )}
          {trial.isVerified && (
            <Icon className='ml-2 text-primary600' icon={faBadgeCheck} />
          )}
        </div>
      </div>

      <div className='w-2/12 text-left'>
        <Text
          className='font-semibold'
          styleName={isPartnerEmbedPage ? 'p-small' : 'p'}
          value={phaseLabel === 'N/A' ? 'Unphased' : phaseLabel}
        />
        {awardBadges.length > 0 && (
          <AwardBadgesHeader awardBadges={awardBadges} />
        )}
      </div>

      <div className='w-2/12'>
        <EligibilityMatch trial={trial} />
      </div>

      <div className='text-right'>
        <Icon
          className={`ml-2 cursor-pointer text-neutral600 ${
            isExpanded ? 'rotate-180' : ''
          } transform transition-all duration-500`}
          icon={faChevronDown}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
    </div>
  )
}

const TreatmentForConditionTitle = ({ trialTitle }: { trialTitle: string }) => {
  const isPartnerEmbedPage = useIsPartnerPage({ onlyEmbedPage: true })
  const [treatment, condition] = trialTitle.split(' for ')

  const hasTreatmentAndCondition = Boolean(treatment) && Boolean(condition)

  if (hasTreatmentAndCondition) {
    return (
      <>
        <Text
          className='line-clamp-2'
          styleName={isPartnerEmbedPage ? 'p-small-strong' : 'p-strong'}
          value={treatment}
        />
        <Text
          className='text-neutral600'
          styleName='p-small'
          value={` for ${condition}`}
        />
      </>
    )
  }

  return (
    <Text
      className='line-clamp-2 text-black'
      hideTitle={true}
      styleName={isPartnerEmbedPage ? 'p-small-strong' : 'p-strong'}
      value={trialTitle}
    />
  )
}
