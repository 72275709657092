import { classMerge } from '@components/utilities/classMerge'
import type { ReactNode } from 'react'

type NumberString = `${number}`
type PixelString = `${NumberString}px`

export const AnimatedAccordion = ({
  children,
  className,
  expandedHeight = '1000px', // You can't set the value to h-auto. Anything bigger will make the animation seem faster
  isExpanded,
}: {
  children: ReactNode
  className?: string
  expandedHeight?: PixelString
  isExpanded: boolean
}) => (
  <div
    className={classMerge('overflow-hidden', className)}
    style={
      isExpanded
        ? {
            maxHeight: expandedHeight,
            transition: 'max-height 0.5s ease-in-out',
          }
        : { maxHeight: '0rem', transition: 'max-height 0.3s  ease-in-out' }
    }
  >
    {children}
  </div>
)
